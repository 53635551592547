import { BsFacebook } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import useIsMobile from "../../hooks/useIsMobile";

const containerStyle = {
  position: "fixed",
  zIndex: 2,
  bottom: 0,
  width: "100vw",
  backgroundColor: "#4B8673",
  color: "white",
  height: "5vh",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const sectionStyle = {
  display: "flex",
  marginLeft: "5vw",
  marginRight: "5vw",
  alignItems: "flex-end",
};

const Footer = () => {
  const isMobile = useIsMobile();
  return (
    <div style={containerStyle}>
      <div style={sectionStyle}>
        <div
          style={{
            marginRight: "3vw",
            fontFamily: "Space Grotesk",
            fontWeight: "bolder",
            display: isMobile ? "none" : "block",
          }}
        >
          contacto@lluvia.com
        </div>
        <div
          style={{
            marginLeft: isMobile ? 0 : "5vw",
            fontFamily: "Space Grotesk",
            fontWeight: "bolder",
          }}
        >
          452-196-3670
        </div>
      </div>
      <div style={sectionStyle}>
        <div style={{ marginRight: "3vw" }}>
          <a
            href="https://www.facebook.com/ARTICULOSDELIMPIEZALLUVIA"
            target="_blank"
            rel="noreferrer"
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
              fontSize: "3vh",
              color: "white",
            }}
          >
            <BsFacebook />
          </a>
        </div>
        <div style={{ fontSize: "3vh" }}>
          <a
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
              fontSize: "3vh",
              color: "white",
            }}
            href="https://api.whatsapp.com/send?phone=5214521963670&text=Hola%2C%20me%20gustar%C3%ADa%20contactar%20con%20ustedes!"
            target="_blank"
            rel="noreferrer"
          >
            <BsWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
