import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  {
    label: "Inicio",
    link: "/",
  },
  {
    label: "Catálogo",
    link: "/productos",
  },
  {
    label: "Nosotros",
    link: "/nosotros",
  },
  {
    label: "Contacto",
    link: "/contacto",
  },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src="images/lluvialogo.png" alt="logo" style={{ width: "20vw" }} />
      <Divider />
      <List>
        {navItems.map(({ label, link }, index) => (
          <ListItem key={label} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link
                to={link}
                style={{
                  color: "black",
                  textDecorationLine: "none",
                  fontSize: "20px",
                  fontFamily: "Space Grotesk",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {label}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                sm: "flex",
                justifyContent: "center",
                gap: "2vw",
              },
            }}
          >
            {navItems.map(({ label, link }, index) => (
              <Button key={index} sx={{ color: "#fff" }}>
                <Link
                  to={link}
                  style={{
                    color: "black",
                    textDecorationLine: "none",
                    fontSize: "20px",
                    fontFamily: "Space Grotesk",
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                >
                  {label}
                </Link>
              </Button>
            ))}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
