import * as React from "react";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const Card = ({ product }) => {
  const [, name, description, price, unit, url] = product;
  return (
    <div style={{ padding: "1vw" }}>
      <MuiCard>
        <CardActionArea>
          <CardMedia component="img" height="200" image={url} alt="producto" />
          <CardContent>
            <h1
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "1.5rem",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {name}
            </h1>
            <p
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "1rem",
                color: "#6C6C6C",
                height: "100%",
                overflowY: "hidden",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              {description}
            </p>
            <p
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "0.8rem",
                color: "#6C6C6C",
                height: "100%",
                overflowY: "hidden",
                marginTop: 0,
                marginBottom: 0,
                fontWeight: "bolder",
              }}
            >
              Costo: ${price}/{unit}
            </p>
          </CardContent>
        </CardActionArea>
      </MuiCard>
    </div>
  );
};

export default Card;
