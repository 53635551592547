import { Grid } from "@mui/material";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import useIsMobile from "../../hooks/useIsMobile";

const About = () => {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        backgroundColor: "#FBFAF4",
        height: isMobile ? "100%" : "100%",
        paddingBottom: "5vh",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#FBFAF4",
          zIndex: -1,
          position: "fixed",
        }}
      />
      <Navbar />
      <Grid
        container
        spacing={4}
        style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
      >
        <Grid item xs={12}>
          <img
            src="images/lluvialogo.png"
            alt="logo"
            style={{
              marginTop: "10vh",
              width: isMobile ? "50vw" : "14vw",
              marginLeft: isMobile ? 0 : "5vw",
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <div>
            <h1
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "2.5rem",
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 0,
              }}
            >
              Nuestra historia
            </h1>
            <p
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "1rem",
                color: "#6C6C6C",
                overflowY: "hidden",
                marginTop: 0,
                textAlign: "justify",
              }}
            >
              Es en el año 2006 cuando comienza nuestra historia en la ciudad de
              Uruapan Michoacán. En un pequeño taller solo nos dedicábamos a la
              fabricación de bastón de madera y trapeador. Un año después
              abrimos una tienda donde ofrecíamos algunos productos para la
              limpieza en el hogar. Siempre pensando en cómo mejorar nuestro
              servicio y atendiendo a las necesidades de nuestros clientes nos
              dimos a la tarea de continuar ampliando la variedad de productos.
              Actualmente distribuimos directo de fábrica mas de 1800 artículos
              que se distinguen por su calidad y bajo precio, seguimos
              fabricando trapeador de diferentes medidas y materiales, así como
              nos especializamos en la producción de líquidos a granel para la
              limpieza. Contamos con cómodas instalaciones donde puedes ser
              atendido de manera personal y profesional, ofreciéndote la ventaja
              de adquirir precios de mayoreo.
            </p>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div>
            <h1
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "2.5rem",
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 0,
              }}
            >
              Misión
            </h1>
            <p
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "1rem",
                color: "#6C6C6C",
                overflowY: "hidden",
                marginTop: 0,
                textAlign: "justify",
              }}
            >
              Satisfacer las necesidades de productos plásticos y de limpieza
              para el hogar de una manera rápida, amable y confiable, obteniendo
              una adecuada rentabilidad para garantizar así nuestra permanencia
              y crecimiento.
            </p>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div>
            <h1
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "2.5rem",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              Visión
            </h1>
            <p
              style={{
                fontFamily: "Space Grotesk",
                fontSize: "1rem",
                color: "#6C6C6C",
                overflowY: "hidden",
                marginTop: 0,
                textAlign: "justify",
                paddingTop: 0,
              }}
            >
              Ser una empresa con cobertura a nivel estatal de productos
              plásticos y de limpieza para el hogar, que mejoren la calidad de
              vida de las familias a partir de productos amigables con el medio
              ambiente, amplio surtido, buen servicio y a buen precio;
              impulsando el crecimiento y desarrollo de nuestros empleados, la
              rentabilidad de los inversionistas y así garantizar la permanencia
              de la empresa para contribuir desarrollo y bienestar de Michoacán.
            </p>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default About;
