import LluviaCarousel from "../../components/carousel/Carousel";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar"

const Landing = () => {
    return <><Navbar />
    <LluviaCarousel/>
    <Footer/></>
}

export default Landing;