import { useCallback, useEffect, useRef, useState } from "react";
import Card from "../../components/card/Card";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { Grid } from "@mui/material";
import TextWTitle from "../../components/texts/TextWTitle";

const inputStyle = {
  width: "100%",
  height: "4vh",
  fontSize: "1rem",
  color: "#9C9C9C",
  fontFamily: "Space Grotesk",
  border: "solid 1px #4B8673",
  borderRadius: "0.438rem",
  paddingLeft: "1.25rem",
};

const Products = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isFetched, setIsFetched] = useState(false);

  function leerCSV() {
    fetch("/productos.txt")
      .then((response) => response.text())
      .then((data) => {
        const rows = data.split(/\r?\n|\r|\n/g);
        const cols = rows.map((row) => row.split("||"));
        setData(cols);
        setIsFetched(true);
      });
  }

  const textFieldRef = useRef();

  const onChange = useCallback(() => {
    setSearch(textFieldRef?.current?.value);
  }, []);

  useEffect(() => {
    leerCSV();
  }, []);

  const showData = useCallback(() => {
    const filteredData = data.filter((product) => product[1].includes(search));
    return filteredData;
  }, [search, data]);

  return (
    <div
      style={{
        backgroundColor: "#FBFAF4",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Navbar />
      <div
        style={{
          marginTop: 80,
          marginBottom: "5vh",
        }}
      >
        <div
          style={{ paddingLeft: "1vw", paddingRight: "1vw", width: "100vw" }}
        >
          <input
            id="mobile"
            type="text"
            name="mobile"
            placeholder="Buscar..."
            ref={textFieldRef}
            onChange={onChange}
            style={inputStyle}
          />
        </div>
        <Grid container justify="center" alignItems="center">
          {isFetched ? (
            showData().length ? (
              showData().map((product) => (
                <Grid xl={2} lg={3} md={4} sm={6} xs={12} key={product[0]}>
                  <Card product={product} />
                </Grid>
              ))
            ) : (
              <div
                style={{
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                  width: "100vw",
                }}
              >
                <TextWTitle title="No se encontraron productos">
                  Escríbenos para preguntar acerca de cualquiera de nuestros
                  productos y nos pondremos en contacto contigo para resolver
                  todas tus dudas.
                </TextWTitle>
                <img
                  src="images/lluvialogo.png"
                  alt="logo"
                  style={{
                    marginTop: "10vh",
                    width: "30vw",
                  }}
                />
              </div>
            )
          ) : (
            <></>
          )}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
