import './App.css';
import Landing from "./views/landing/Landing"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Contact from './views/contact/Contact';
import About from './views/about/About';
import Products from './views/Products/Product';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  }, {
    path: "/contacto",
    element: <Contact />,
  }, {
    path: "/nosotros",
    element: <About />,
  }, {
    path: "/productos",
    element: <Products />,
  }, {
    path: "/*",
    element: <Landing />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
