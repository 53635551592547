import { Grid } from "@mui/material";
import { useRef } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import TextWTitle from "../../components/texts/TextWTitle";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import useIsMobile from "../../hooks/useIsMobile";

const inputStyle = {
  width: "100%",
  height: "4vh",
  fontSize: "1rem",
  color: "#9C9C9C",
  fontFamily: "Space Grotesk",
  border: "solid 1px #4B8673",
  borderRadius: "0.438rem",
  paddingLeft: "1.25rem",
};

const Contact = () => {
  const formRef = useRef(null);
  const isMobile = useIsMobile();

  const sendMail = (fullMessage, name) => {
    document.location.href =
      "mailto:articulosdelimpiezalluvia@hotmail.com?subject=" +
      encodeURIComponent(name) +
      "&body=" +
      encodeURIComponent(fullMessage);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const name = formRef.current[0].value;
    const email = formRef.current[1].value;
    const phone = formRef.current[2].value;
    const mobile = formRef.current[3].value;
    const message = formRef.current[4].value;

    const fullMessage = `${message}
    Nombre: ${name}
    Correo: ${email}
    Celular: ${mobile}
    Telefono: ${phone}
    `;

    sendMail(fullMessage, name);
  };

  return (
    <div
      style={{
        backgroundColor: "#FBFAF4",
        height: isMobile ? "100%" : "100vh",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#FBFAF4",
          zIndex: -1,
          position: "fixed",
        }}
      />
      <Navbar />
      <Grid
        container
        spacing={0}
        style={{
          paddingBottom: "5vh",
        }}
      >
        <Grid item xs={12} md={5}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: isMobile ? "center" : "start",
            }}
          >
            <img
              src="images/lluvialogo.png"
              alt="logo"
              style={{
                marginTop: "10vh",
                width: isMobile ? "50vw" : "14vw",
                marginLeft: isMobile ? 0 : "5vw",
              }}
            />
          </div>
          <div
            style={{
              fontSize: "1rem",
              fontFamily: "Space Grotesk",
              marginTop: "3vh",
              display: "flex",
              marginLeft: "5vw",
              alignItems: "center",
            }}
          >
            <LocalPhoneOutlinedIcon style={{ marginRight: "1vw" }} />
            452-196-3670
          </div>
          <div
            style={{
              fontSize: "1rem",
              fontFamily: "Space Grotesk",
              marginTop: "2vh",
              display: "flex",
              marginLeft: "5vw",
              alignItems: "center",
            }}
          >
            <EmailOutlinedIcon style={{ marginRight: "1vw" }} />
            articulosdelimpiezalluvia@hotmail.com
          </div>
          <div
            style={{
              fontSize: "1rem",
              fontFamily: "Space Grotesk",
              marginTop: "2vh",
              display: "flex",
              marginLeft: "5vw",
              alignItems: "center",
            }}
          >
            <LocationOnOutlinedIcon style={{ marginRight: "1vw" }} />
            Privada de Prol. Jalisco 172, Uruapan, Mich.
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.852829932336!2d-102.04432598461278!3d19.4187637461538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de2446445138f%3A0x2345f96db52751b6!2sPrivada%20de%20Prol.%20Jalisco%20172%2C%20El%20Inguambal%2C%2060130%20Uruapan%2C%20Mich.!5e0!3m2!1ses-419!2smx!4v1680470297661!5m2!1ses-419!2smx"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
            style={{
              width: "90%",
              height: "40vh",
              marginLeft: "5vw",
              borderWidth: 0,
            }}
          ></iframe>
        </Grid>
        <Grid item xs={12} md={7}>
          <div
            style={{
              marginTop: "9vh",
              paddingLeft: "10vw",
              paddingRight: "10vw",
            }}
          >
            <TextWTitle title="Contacta con nosotros">
              Escríbenos para preguntar acerca de cualquiera de nuestros
              productos y nos pondremos en contacto contigo para resolver todas
              tus dudas. También puedes dejar un comentario o sugerencia para
              mejorar nuestro servicio.
            </TextWTitle>
          </div>
          <form action="" ref={formRef}>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "5vw", paddingRight: "5vw" }}
            >
              <Grid item xs={6}>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  style={inputStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Correo"
                  style={inputStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  id="phone"
                  type="text"
                  name="phone"
                  placeholder="Teléfono"
                  style={inputStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  id="mobile"
                  type="text"
                  name="mobile"
                  placeholder="Celular"
                  style={inputStyle}
                />
              </Grid>
              <Grid item xs={12}>
                <textarea
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Escribe tu mensaje"
                  style={{ ...inputStyle, height: "38vh" }}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  type="reset"
                  value="Limpiar"
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "black",
                    height: "4vh",
                    borderRadius: "0.438rem",
                    fontSize: "1rem",
                    fontFamily: "Space Grotesk",
                    marginBottom: isMobile ? "6vh" : 0,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  type="submit"
                  value="Enviar"
                  onClick={onSubmit}
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "black",
                    height: "4vh",
                    borderRadius: "0.438rem",
                    fontSize: "1rem",
                    fontFamily: "Space Grotesk",
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Contact;
