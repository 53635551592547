import { Carousel } from "antd";
import useIsMobile from "../../hooks/useIsMobile";
import "./Carousel.css";

const carouselImages = [
  "Más de 1800 productos de limpieza",
  "Para tu hogar...",
  "Para tu negocio...",
  "Para tu empresa...",
  "O simplemente para ti...",
];

const imageStyle = {
  maxWidth: "100%",
  height: "95vh",
  backgroundSize: "cover",
  paddingTop: "15vh",
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "5vw",
  paddingRight: "5vw",
};

const imageMobileStyle = {
  maxWidth: "100%",
  height: "90vh",
  backgroundSize: "cover",
  paddingTop: "15vh",
};

const containerStyle = {
  height: "95vh",
  overflowY: "hidden",
};

const LluviaCarousel = () => {
  const isMobile = useIsMobile();
  return (
    <div style={containerStyle}>
      <Carousel autoplay dots="dots">
        {carouselImages.map((text, index) => {
          return (
            <div>
              <div
                style={{
                  ...(isMobile ? imageMobileStyle : imageStyle),
                  backgroundImage: `url("/images/carousel-${index + 1}.jpg")`,
                }}
              >
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <img
                      src="images/lluvialogo.png"
                      alt="logo"
                      style={{
                        width: isMobile ? "50vw" : "14.7vw",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "3.1rem",
                    width: isMobile ? "100vw" : "50vw",
                    fontFamily: "Space Grotesk",
                    fontWeight: "bolder",
                    textAlign: isMobile ? "center" : "end",
                  }}
                  className="header"
                >
                  {text}
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default LluviaCarousel;
