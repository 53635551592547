const TextWTitle = ({ title, children, style }) => {
  return (
    <div>
      <h1
        style={{
          fontFamily: "Space Grotesk",
          fontSize: "2.5rem",
          marginBottom: 0,
        }}
      >
        {title}
      </h1>
      <p
        style={{
          fontFamily: "Space Grotesk",
          fontSize: "1rem",
          color: "#6C6C6C",
          height: "100%",
          overflowY: "hidden",
          marginTop: 0,
        }}
      >
        {children}
      </p>
    </div>
  );
};

export default TextWTitle;
